<template>
    <div v-if="apiContent.colors" class="content overview" :style="'background-color:' + apiContent.colors.colorOverview.hex + ';'">
        <router-link :to="'/project/' + project._key + '/' + hyphenation(project.projectTitle)" v-for="project in apiContent.projects" :key="project._key">
            <img :src="imageUrlFor(project.projectCover.asset._ref)">
            <p>{{ project.projectTitle }}</p>            
            <div class="titletip" :style="styles" >
                <span v-if="project.projectTitleColor" :style="'color: ' + project.projectTitleColor.hex">{{ project.projectTitle }}</span>
                <span v-else>{{ project.projectTitle }}</span>
            </div>
        </router-link>        
    </div>
</template>
<script>
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity);

export default {

    props: ['apiContent'],
    data: function () {
        return {
            x: 0,
            y: 0,
            projects:[],
            ovy: 0,
            ovynew: 0
        }
    },
    methods: {
        setmovY() {
            if(localStorage.tmy) {    
                window.scrollTo({left: 0, top: localStorage.tmovy, behavior: "instant"});
            }
        },
        imageUrlFor(source) {
            return imageBuilder.image(source);
        },    
        hyphenation(str) {
            return str.replace(/ +/g, '-').toLowerCase();
        },
        onMouseMove (ev) {
            this.x = ev.clientX + document.documentElement.scrollLeft
            this.y = ev.clientY + document.documentElement.scrollTop
        },
        handleScroll () {
            this.onMouseMove()
            this.ovy = window.top.scrollY
            if ((this.$route.path) === '/overview') {
                localStorage.tmovy = this.ovy;
            }
        },
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll);
        document.addEventListener('mousemove', this.onMouseMove)
        let app = this
        setTimeout(function(){
            app.setmovY()
        }, 200);
    },
    unmounted () {
      window.removeEventListener('scroll', this.handleScroll);
      document.removeEventListener('mousemove', this.onMouseMove)
    },
    computed: {
        styles () {
            return {
                left: 'calc(' + this.x + 'px - 12vw)', 
                top: this.y + 'px', 
            }
        }
    },
    }
</script>

<style lang="scss">

.overview {
    padding: 40px 20px;
    @media only screen and (max-width: 768px) {
        padding: 30px 15px;
    }
    a {
        text-decoration: none;
        vertical-align: top;
        margin: 0px 30px 50px 20px;
        display: inline-block;
        @media only screen and (max-width: 768px) {
            margin: 0 12px 22px 12px;
        }
        img {
            height: 200px;
            @media only screen and (max-width: 768px) {
                height: 190px;
            }
        }
        p {
            font-size: 14px;
            line-height: 20px;
            display: none;
            @media only screen and (max-width: 768px) {
                display: block;
            }
        }
        .titletip {
            display: none;
            position: absolute;
            padding: 3px;
            // background: rgba(255,255,255,0.7);
            color: black;
            font-size: 14px;
            font-weight: 600;
            transform: translate(-50%, -35px);
            pointer-events: none;
            z-index: 100x;
            @media only screen and (max-width: 768px) {
                display: none !important;
            }
        }
        &:hover .titletip {
            display: block;
        }
    }
}

</style>