<template>
    <div v-if="apiContent.colors" class="content project">
        <div v-for="project in apiContent.projects" :key="project._key">
            <div class="project-wrap" v-if="project._key === $attrs.id" :style="project.projectBgColor ? 'background-color: ' + project.projectBgColor.hex : 'background-color:' + apiContent.colors.colorProject.hex + ';'">
                <h2 v-if="project.projectPageTitleColor" :style="'color: ' + project.projectPageTitleColor.hex">{{ project.projectTitle }}</h2>
                <h2 v-else>{{ project.projectTitle }}</h2>
                <div class="project-items">
                    <img v-for="image in project.imagesGallery" :key="image._key" :src="imageUrlFor(image.asset._ref)">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity);

export default {
    props: ['apiContent'],
    data: function () {
        return {
            sectionColor: '',
        }
    },
    methods: {
        imageUrlFor(source) {
            return imageBuilder.image(source);
        },    
        hyphenation(str) {
            return str.replace(/ +/g, '-').toLowerCase();
        },        
    },
    mounted() {
        window.scrollTo({left: 0, top: 0, behavior: "instant"});
    }
}
</script>
<style lang="scss">

.project {
    overflow: hidden;
    &-wrap {
        padding: 80px 40px;
        height: 100vh;
        width: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        padding-right: 25vw;
        @media only screen and (max-width: 768px) {
            padding: 20px 15px;
            display: block;
            padding-right: 15px;
        }
    }
    h2 {
        font-size: 18px;
        line-height: 1;
        margin-bottom: 50px;
        position: absolute;
        top: 107px;
        @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
            position: static;
        }
    }
    &-items {        
        display: flex;    
        @media only screen and (max-width: 768px) {
            display: block;
        }
        img {
            height: 50vh;
            min-height: 380px;
            margin-right: 70px;
            @media only screen and (max-width: 768px) {
                width: 100%;
                height: auto;
                min-height: auto;
                margin-bottom: 30px;
                margin-right: 0;
            }

        }
    }
}

</style>