<template>
    <div class="home" ref="home">
      <div class="home-projects" v-if="apiContent.homepage">
        <div
          :class="'home-project ' + project.homePlacement"
          v-for="project in apiContent.homepage" 
          :key="project._key"
          :style="'background: linear-gradient(' + bgAngle + ', ' + setBackground(project.homeLeft) + ' 50%, ' + setBackground(project.homeRight) + ' 50%);'"

          >
          <img v-if="bgAngle === '90deg'" :style="imgStyles(project.homePosition, project.homeScale)" :src="imageUrlFor(project.homeImage.asset._ref)">
          <img v-else :style="'transform: scale(' + project.homeScale + '%);'" :src="imageUrlFor(project.homeImage.asset._ref).width(780)">
        </div>
      </div>
    </div>
</template>

<script>
          // :style="'background: linear-gradient(' + bgAngle + ', rgba(' + project.homeLeft.rgb.r + ',' + project.homeLeft.rgb.g + ',' + project.homeLeft.rgb.b + ',' + project.homeLeft.rgb.a + ') 50%, rgba(' + project.homeRight.rgb.r + ',' + project.homeRight.rgb.g + ',' + project.homeRight.rgb.b + ',' + project.homeRight.rgb.a + ') 50%);'"
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity);

export default {
  props: ['apiContent'],
  data: function () {
    return {
      bgAngle: '90deg',
      projects: [],
      y: 0,
      ynew: 0
    }
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },    
    setY() {
      if(localStorage.tmy) {    
        window.scrollTo({left: 0, top: localStorage.tmy, behavior: "instant"});
      }
    },
    imgStyles(position, scale) {
        return {
            transform: 'translateX(' + position + '%) scale(' + scale*0.01 + ')',
        }      
    },
    resizeBrowserHandler (e) {
        // mobile screen
        if (e.target.innerWidth < 768) {
            this.bgAngle = '180deg'
        } else {
            this.bgAngle = '90deg'
        }
    },
    handleScroll () {
      this.y = window.top.scrollY
      if ((this.$route.path) === '/') {
        localStorage.tmy = this.y;
      }
    },
    setBackground(condition) {
      if (condition) {
        return 'rgba(' + condition.rgb.r + ',' + condition.rgb.g + ',' + condition.rgb.b + ',' + condition.rgb.a + ')'
      } else {
        return 'rgba(0,0,0,0)'
      }
    }
  },
  mounted() {
    let app = this
    setTimeout(app.setY(), 100)
  },
  created() {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener("resize", this.resizeBrowserHandler);
        if (window.innerWidth < 768) {
            this.bgAngle = '180deg'
        } else {
            this.bgAngle = '90deg'
        }
    },
  unmounted() {
      window.removeEventListener("resize", this.resizeBrowserHandler);
      window.removeEventListener('scroll', this.handleScroll);
  },
}   
 

</script>
<style lang="scss">
.home {
  &-projects {
    min-width: 100%;
    width: auto;
    &.locked {
      height: 100vh;
      width: 100vh;
      overflow: hidden;
    }
  }
  &-project {
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    &.left {
      justify-content: flex-start;
    }
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }
    @media only screen and (max-width: 768px) {
      align-items: center !important;      
    }
    img {
      height: 100%;
      @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;        
      }
    }
  }
}
</style>
