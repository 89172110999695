<template>
    <div class="home" ref="home">
      <div class="home-projects" v-if="portfolios.Items">
        <div
          :class="'home-project ' + project.pfPlacement"
          v-for="project in portfolios.Items" 
          :key="project._key"          
          :style="'background: linear-gradient(' + bgAngle + ', ' + setBackground(project.pfLeft) + ' 50%, ' + setBackground(project.pfRight) + ' 50%);'"

          >
          <img v-if="bgAngle === '90deg'" :style="'transform: translateX(' + project.pfPosition + '%) scale(' + project.pfScale + '%);'" :src="imageUrlFor(project.pfImage.asset._ref)">
          <img v-else :style="'transform: scale(' + project.pfScale + '%);'" :src="imageUrlFor(project.pfImage.asset._ref)">
        </div>
      </div>
    </div>
</template>

<script>
          // :style="'background: linear-gradient(' + bgAngle + ', rgba(' + project.homeLeft.rgb.r + ',' + project.homeLeft.rgb.g + ',' + project.homeLeft.rgb.b + ',' + project.homeLeft.rgb.a + ') 50%, rgba(' + project.homeRight.rgb.r + ',' + project.homeRight.rgb.g + ',' + project.homeRight.rgb.b + ',' + project.homeRight.rgb.a + ') 50%);'"
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity);
export default {
  props: ['apiContent'],
  data: function () {
    return {
      bgAngle: '90deg',
      portfolios: [],            
    }
  },
  methods: {
    fetchData() {
      sanity.fetch(`*[_type == "portfolios" && pfUrl == "` + this.$route.params.title +`"]`).then(
        (content) => {
          this.portfolios = content[0]
        },
        (error) => {
          this.error = error;
        }
      );
    },
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },    
    resizeBrowserHandler (e) {
        // mobile screen
        if (e.target.innerWidth < 768) {
            this.bgAngle = '180deg'
        } else {
            this.bgAngle = '90deg'
        }
    },
    handleScroll () {
      this.y = window.top.scrollY
      if ((this.$route.path) === '/') {
        localStorage.tmy = this.y;
      }
    },
    setBackground(condition) {
      if (condition) {
        return 'rgba(' + condition.rgb.r + ',' + condition.rgb.g + ',' + condition.rgb.b + ',' + condition.rgb.a + ')'
      } else {
        return 'rgba(0,0,0,0)'
      }
    }
  },
  mounted() {
  },
  created() {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener("resize", this.resizeBrowserHandler);
        if (window.innerWidth < 768) {
            this.bgAngle = '180deg'
        } else {
            this.bgAngle = '90deg'
        }
        this.fetchData();
    },
  unmounted() {
      window.removeEventListener("resize", this.resizeBrowserHandler);
      window.removeEventListener('scroll', this.handleScroll);
  },
}   
 

</script>
<style lang="scss">
.home {
  &-projects {
    min-width: 100%;
    width: auto;
    &.locked {
      height: 100vh;
      width: 100vh;
      overflow: hidden;
    }
  }
  &-project {
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    &.left {
      justify-content: flex-start;
    }
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }
    @media only screen and (max-width: 768px) {
      align-items: center !important;      
    }
    img {
      height: 100%;
      @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;        
      }
    }
  }
}
</style>
