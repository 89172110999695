import { createRouter, createWebHistory } from 'vue-router'
import Hello from '../components/Hello'
import Info from '../components/Info'
import Overview from '../components/Overview'
import Project from '../components/Project'
import Portfolio from '../components/Portfolio'

const routes = [
    { path: '/', component: Hello, props: true},
    { path: '/info', component: Info, props: true},
    { path: '/overview', component: Overview, props: true},
    { path: '/project/:id/:title', component: Project, props: true},
    { path: '/portfolio/:title', component: Portfolio, props: true},
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
