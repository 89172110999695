<template>
    <div v-if="apiContent.colors" class="content info" :style="styles">
        <SanityBlocks v-if="apiContent.infoText2" :blocks="blocks" :serializers="serializers" />
    </div>
</template>
<script>
import { SanityBlocks } from 'sanity-blocks-vue-component';

export default {
  props: ['apiContent'],
  components: { SanityBlocks },
  data: function () {
      return {
      }
  },
  computed: {
      styles () {
          return {
              color: this.apiContent.infoTextColor.hex, 
              fontSize: this.apiContent.infoTextSize + 'px', 
              backgroundColor: this.apiContent.colors.colorInfo.hex
          }
      },
      blocks () {
        return this.apiContent.infoText2
      },
      serializers () {
        return {
          marks: {
            link: (teste, children) => <a target='_blank' href={teste.href}>{children}</a>
          }
        }
      },
  },
}
</script>

<style lang="scss">

.info {
  padding: 60px;
  font-size: 18px;
  white-space: pre-line;
  p {
    font-size: inherit;
    color: inherit;
  }
  a {
    font-size: inherit;
    color: inherit;
  }
}

</style>